import axios from 'axios';
//import firebase from 'firebase';
import * as firebase from 'firebase/app';
import milligram from 'milligram';

import 'firebase/auth';
import './main.css';
import './spinner.css';

const storageKey = '__TWIGA_USER__';

const q = q => {
	return document.querySelector(q);
};

// email personalization settings (can be saved in local storage)
var user = {
	department: '',
	role: '',
	phone: '',
	address: '',
	email: '',
	token: '',
	name: ''
};

var rawUser = localStorage.getItem(storageKey);

if (rawUser) {
	user = JSON.parse(rawUser);
}

// update inputs with previous data
q('#department').value = user.department;
q('#role').value = user.role;
q('#phone').value = user.phone;
q('#address').value = user.address;

// display support email address
try {
	const smail = atob('dGVjaHN1cHBvcnRAdHdpZ2Fmb29kcy5jb20=');
	const supportElem = document.querySelector('#support-mail');
	supportElem.innerHTML = smail;
	supportElem.setAttribute('href', 'mailto:' + smail);
} catch (e) {
	console.log(e.message);
}

// Display an alert
const alert = (message, type = 'danger') => {
	const alertElem = document.querySelector('#alert');

	alertElem.innerHTML = message;

	if (message.length > 0) {
		alertElem.classList.remove('hide');
	} else {
		alertElem.classList.add('hide');
	}

	alertElem.classList.remove('danger');
	alertElem.classList.remove('alert-success');

	alertElem.classList.add(type);
};

// Get personalized email signature
const getSignature = user => {
	let signature = `
  <div style="font-family: sans-serif; font-size: 10pt; color: #606c76; letter-spacing: .01em; line-height: 1.5;">
    Kind Regards,<br />
    <strong>${user.name}, ${user.role}</strong> <br />
    Tel: ${user.phone} | Email:
    <a href="mailto:${user.email}" target="_blank" style="color: #606c76; text-decoration: underline;">${user.email}</a><br />
    ${user.address}
    <div style="padding-top: 5px">
      <img src="https://www.google.com/a/cpanel/twigafoods.com/images/logo.gif?service=google_gsuite" alt="" width="100" style="width:100px; max-width: 100px">
    </div>
    <div>
      Soko Yetu - <a href="https://www.twiga.ke" target="_blank" style="color: #606c76; text-decoration: underline;">www.twiga.ke</a>
    </div>
  </div>`;

	return signature;
};

// Method to update your email signature
const updateSignature = user => {
	loading = true;
	loadingSpinner(loading);

	const instance = axios.create({
		baseURL: 'https://www.googleapis.com/gmail/v1/users/me/settings/sendAs/',
		headers: { Authorization: 'Bearer ' + user.token }
	});

	const signature = {
		sendAsEmail: user.email,
		signature: `${getSignature(user)}`
	};

	instance
		.put(user.email, signature)
		.then(response => {
			console.log('__AXIOS__', response);

			alert(`Your Email Signature has been updated. Please confirm by composing a new email`, 'alert-success');

			loading = false;
			loadingSpinner(loading);
		})
		.catch(error => {
			console.log('__AXIOS__', error.response);
			alert(`Error updating signature. Error: ${JSON.stringify(error)}`);

			loading = false;
			loadingSpinner(loading);
		});
};

// Initialize firebase config
var config = {
	apiKey: 'AIzaSyCMaqJUDRcCai2TvblWF8dKCSZyYLu-E0M',
	authDomain: 'email-signature-207818.firebaseapp.com',
	//authDomain: "signature.twiga.tech",
	databaseURL: 'https://email-signature-207818.firebaseio.com',
	projectId: 'email-signature-207818',
	storageBucket: '',
	messagingSenderId: '721972903684'
};

firebase.initializeApp(config);

// handle firebase auth from Google Sign-in redirect
firebase
	.auth()
	.getRedirectResult()
	.then(function(result) {
		loading = true;
		loadingSpinner(loading);

		if (result.credential && result.user) {
			user.name = result.user.displayName;
			user.email = result.user.email;
			user.token = result.credential.accessToken;

			console.log('__AFTER_AUTH__', user);

			updateSignature(user);
		} else {
			loading = false;
			loadingSpinner(loading);
			console.log('__FIREBASE__', { message: 'Error Loading user' });
		}
	})
	.catch(function(error) {
		// Handle Errors here.
		var errorCode = error.code;
		var errorMessage = error.message;
		// The email of the user's account used.
		var email = error.email;
		// The firebase.auth.AuthCredential type that was used.
		var credential = error.credential;

		alert(`Authentication Error. Code: ${errorCode} Message: ${errorMessage}`);

		console.log('__ERROR__', error);
	});

// setup firebase auth provider
var provider = new firebase.auth.GoogleAuthProvider();

// request for necessary permissions to update gmail settings
provider.addScope(
	'profile email https://www.googleapis.com/auth/gmail.settings.sharing https://www.googleapis.com/auth/gmail.settings.basic'
);

provider.setCustomParameters({
	hd: 'twigafoods.com'
});

var loading = false;

const validate = user => {
	if (user.department.length < 1 || user.role.length < 1 || user.phone.length < 1) {
		return false;
	} else {
		return true;
	}
};

const loadingSpinner = (loading = false) => {
	const elem = document.querySelector('.loader-container');

	if (loading) {
		elem.classList.remove('hidden');
	} else {
		elem.classList.add('hidden');
	}
};

// setup the onclick listener
document.querySelector('#update-signature').onclick = () => {
	alert('');

	if (loading) {
		console.log('Be patient my friend');
		return;
	}

	user.department = q('#department').value;
	user.role = q('#role').value;
	user.phone = q('#phone').value;
	user.address = q('#address').value;

	if (!validate(user)) {
		alert('Please fill all the required fields');
		return;
	}

	localStorage.setItem(storageKey, JSON.stringify(user));

	loading = true;

	loadingSpinner(loading);

	firebase.auth().signInWithRedirect(provider);
};

// update copyright year
document.querySelector('.copyright span').innerHTML = new Date().getFullYear();
